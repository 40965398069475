import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, FreeMode, Thumbs } from "swiper";
import { AiFillCloseCircle } from "react-icons/ai";

export const SwiperGallery = ({ imagesDB, handleClickClose }) => {
  // const { rpdata } = useContext(GlobalDataContext);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div>
      <div className="w-full flex justify-between items-center pb-3">
        <h2>{imagesDB.name}</h2>
        <div>
            <button
                onClick={handleClickClose}
                className="bg1 text-white p-1 rounded-full text-center text-2xl"
            >
                <AiFillCloseCircle />

            </button>
        </div>
      </div>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {imagesDB?.gallery.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <img
                src={item}
                className="w-full h-[500px] object-contain"
                alt="none"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
            480: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            640: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            

        }}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper pt-5"
      >
        {imagesDB?.gallery.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <img
                src={item}
                className="w-full h-[100px] object-cover"
                alt="none"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default SwiperGallery;
