import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import ServicesHome from "../Services/ServicesHome_6";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay  } from 'swiper';

function ContentServicesHome() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="md:w-[70%] mx-auto my-12 text-center">
        <h2 className="text-center py-4">
          {rpdata?.labels?.general?.titleServices}
        </h2>
        <ButtonContent
          btnStyle="three"
          btnName="view all services"
          btnLink={"services"}
        />
        {/* <div className="flex flex-wrap items-center justify-center"> */}
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={5}
          slidesPerView={3}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 5,
            },
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {rpdata?.dbServices.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <ServicesHome
                  key={index}
                  bgImg={item.description[0].img}
                  serviceName={item.name}
                  serviceText={
                    item.description[0].text.substring(0, 160) + "..."
                  }
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {/* </div> */}
    </>
  );
}

export default ContentServicesHome;
