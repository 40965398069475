import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import { ButtonContent } from "../components/global/boton/ButtonContent";


function CreditCard() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName="Credit Card & POS Services">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Credit Card & POS Services"
          Subheader="Credit Card & POS Services"
          bgimg={`${rpdata?.gallery?.[5]}`}
        />
      </div>

        <div className="w- full md:w-4/5 md:mx-auto mx-[25%] py-[100px] justify-center text-center">
          <h3>
            {rpdata?.dbSlogan?.[9].slogan}
          </h3>
          <ButtonContent btnphone={rpdata?.dbPrincipal?.phones[0].phone} btnName={`Call Now`} btnStyle={`four`}/>

          {rpdata?.tiposPago?.length > 1 ? (
            <div className="pb-5 items-center justify-center text-center md:flex flex-wrap gap-5">
                {rpdata?.tiposPago?.slice(1).map((item, index) => {
                  return (
                    <img className="md:w-[15%] w-[100%]" src={item.img} alt=""/>
                  );
                })}
            </div>
          ) : null}
      </div>
    </BaseLayout>
  );
}

export default CreditCard;